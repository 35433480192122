import { navigate } from 'gatsby';
import React, { useRef, useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import Container from 'components/Container/Container';
import InnerContainer from 'components/InnerContainer/InnerContainer';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Title from 'components/Title/Title';

import { useCartContext } from 'hooks/useCartContext';
import useParam from 'hooks/useParam';

// import fbqEvent from 'utilities/fbq';
import dataLayerPush from 'utilities/dataLayer';

const iconMb = { marginBottom: '1rem' };

const SuccessPage = () => {
  const isUnmounted = useRef(false);
  const orderId = useParam('orderId');
  const { emptyCart } = useCartContext();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const { cart, totals } = JSON.parse(
          window.localStorage.getItem('cart')
        );
        // fbqEvent('track', 'Purchase', {
        //   value: Number.parseFloat(totals.sum),
        //   currency: 'EUR',
        //   contents: cart.map((item) => ({
        //     id: item.product.sku,
        //     quantity: item.quantity,
        //   })),
        // });
        dataLayerPush({ ecommerce: null });
        dataLayerPush({
          ecommerce: {
            purchase: {
              actionField: {
                id: orderId,
                revenue: Number.parseFloat(totals.sum),
              },
              products: cart.map((item) => ({
                id: item.product.masterSku,
                quantity: item.quantity,
                name: item.product.title,
                price: item.product.finalPrice / 100,
              })),
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    emptyCart();

    if (!isUnmounted.current && !orderId) {
      if (typeof window !== 'undefined') {
        navigate('/');
      }
      return null;
    }

    return () => {
      isUnmounted.current = true;
    };
  }, [emptyCart, isUnmounted, orderId]);

  return (
    <Layout>
      <Seo title="Επιτυχής συναλλαγή" />
      <Container style={{ paddingBottom: '4rem' }}>
        <InnerContainer>
          <Title>Επιτυχής συναλλαγή</Title>
          <FaCheckCircle size="2.5rem" color="#009944" style={iconMb} />
          <p>Ευχαριστούμε που μας προτιμήσατε!</p>
          <p>
            Η συναλλαγή με κωδικό <b>{orderId}</b> ολοκληρώθηκε με επιτυχία!
          </p>
          <p>
            Σας έχει σταλεί ενημερωτικό email με τις λεπτομέρειες της
            παραγγελίας.
          </p>
        </InnerContainer>
      </Container>
    </Layout>
  );
};
export default SuccessPage;
